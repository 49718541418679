<template>
  <a-button class="editable-add-btn" @click="openModal" type="primary" style="margin-bottom: 16px">Добавить токен</a-button>
  <a-modal
    title="Добавить токен"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="confirmLoading"
    @ok="create"
    @cancel="resetForm"
  >
    <a-form :model="token" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="Application ID">
        <a-input
          ref="tokenName"
          v-model:value="token.application_id"
          placeholder="Введите application_id"
        />
      </a-form-item>
      <a-form-item label="Филиал">
        <a-select
          ref="stores"
          show-search
          mode="multiple"
          placeholder="Выберите филиал"
          v-model:value="token.store_ids"
          :filter-option="filter"
          :options="storesList"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import apiClient from "@/services/axios";
import {ref, reactive, toRaw, nextTick} from "vue";
import Token from "@/models/Token";

const emit = defineEmits(['reload'])

let loading = ref(false),
  confirmLoading = ref(false),
  modalVisible = ref(false),
  tokenName = ref("tokenName"),
  labelCol = { span: 9 },
  wrapperCol = { span: 13 },
  storesList = ref([]),
  phone = ref(''),
  token = reactive(new Token({}));

const
  filter = (input, option) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  },
  openModal = () => {
    modalVisible.value = true;
    nextTick(() => tokenName.value?.focus());
  },
  resetForm = () => {
    Object.assign(token, new Token({}))
    modalVisible.value = false;
  },
  create = () => {
    confirmLoading.value = true;

    let model = toRaw(token);

    return apiClient
      .post('/token', model)
      .then(response => {
        if (response) {
          resetForm()
        }
      }).catch(() => {
        // console.log(err)
      }).finally(() => {
        confirmLoading.value = false;
        modalVisible.value = false;
        emit('reload')
      })
  };

apiClient.get(`/store/fetch`).then(({data}) => {
  storesList.value = data.data
})

</script>
