<template>
  <a-modal
    title="Редактировать токен"
    v-model:visible="modalVisible"
    :confirm-loading="confirmLoading"
    :closable="false"
    @ok="update"
    @cancel="resetForm"
  >
    <a-form :model="token" :label-col="labelCol" :wrapper-col="wrapperCol">
      <div class="spin-loader" v-if="fetching">
        <a-spin />
      </div>
      <div v-else>
        <a-form-item label="Application ID">
          <a-input
            ref="tokenRef"
            v-model:value="token.application_id"
            placeholder="Введите application_id"
          />
        </a-form-item>
        <a-form-item label="Филиал">
          <a-select
            ref="stores"
            show-search
            mode="multiple"
            placeholder="Выберите филиал"
            v-model:value="token.store_ids"
            :filter-option="filter"
            :options="storesList"
          />
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import apiClient from "@/services/axios";
import {ref, toRaw, nextTick, reactive} from "vue";
import Token from "@/models/Token";

const props = defineProps({
  tokenId: {
    type: Number,
    default: 0,
  },
})

const emit = defineEmits(['hide-modal', 'reload'])

let fetching = ref(true),
  loading = ref(true),
  confirmLoading = ref(false),
  modalVisible = ref(true),
  tokenRef = ref("tokenRef"),
  labelCol = {span: 9},
  phone = ref(''),
  wrapperCol = {span: 13},
  storesList = ref([]),
  token = reactive(new Token({}));

const
  filter = (input, option) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  },
  resetForm = () => {
    Object.assign(token, new Token({}))
    modalVisible.value = false;
    emit('hide-modal')
  },
  update = () => {
    confirmLoading.value = true;

    let model = toRaw(token)

    return apiClient
      .put(`/token/update?id=${props.tokenId}`, model)
      .then(response => {
        if (response) {
          resetForm()
        }
      }).catch(() => {
        // console.log(err)
      }).finally(() => {
        confirmLoading = false;
        modalVisible.value = false;
        emit('reload')
        emit('hide-modal')
      })
  };

apiClient.get(`/store/fetch`).then(({data}) => {
  storesList.value = data.data
})
apiClient.get(`/token/view?id=${props.tokenId}`).then(response => {
  if (response) {
    let model = new Token(response.data.data.token)
    model.store_ids = model.store_ids.split(',')
    Object.assign(token, model)
  }
}).catch(() => {
  // console.log(err)
}).finally(() => {
  fetching.value = false;
  nextTick(() => tokenRef.value?.focus());
})
</script>

<style scoped>
.spin-loader {
  text-align: center;
  background: #ffffff;
  border-radius: 4px;
  padding: 30px 50px;
  margin: 20px 0 20px;
}
</style>
