<template>
  <div>
    <a-affix :offset-top="0">
      <a-page-header class="header-affix mb-4">
        <a-form>
          <div class="row">
            <div class="col-12 mb-4">
              <div class="row">
                <div class="col-md-2">
                  <a-input v-model:value="applicationId" placeholder="Поиск по имени" @change="find" allow-clear />
                </div>
                <div class="col-md-2">
                  <a-select
                    v-model:value="store"
                    :options="stores"
                    @change="searchByStore"
                    style="width: 100%"
                    :option-filter-prop="'label'"
                    show-search
                  />
                </div>
                <div class="col-md-2">
                  <a-select
                    v-model:value="brand"
                    :options="brands"
                    @change="searchByBrand"
                    style="width: 100%"
                    :option-filter-prop="'label'"
                    show-search
                  />
                </div>
                <div class="col-md-6 text-right">
                  <a-button class="w-100 text-right" @click="clearFilters" shape="round" type="link">
                    <template #icon><CloseCircleOutlined /></template>
                    Сбросить фильтр
                  </a-button>
                </div>
              </div>
            </div>
          </div>
        </a-form>
      </a-page-header>
    </a-affix>
    <vb-create-token @reload="reload" />
    <vb-edit-token
      v-if="modalVisible"
      :token-id="tokenId"
      @reload="reload"
      @hide-modal="hideModal" />
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      @change="tableChange"
      row-key="id"
    >
      <template #stores="{ record }">
        <a-tag
          v-for="item in record.stores"
          :key="item.id"
          color="blue"
          class="mb-1"
        >{{ stores.find((i) => i.value === item.id.toString())?.label }}</a-tag>
      </template>
      <template #date="{ record }">
        <span>
          {{ formattedDatetime(record['added_on']) }}
        </span>
        <span v-if="record.author">
          ({{ record.author }})
        </span>
      </template>
      <template #updated="{ record }">
        <span v-if="record['updated_on']">
          {{ formattedDatetime(record['updated_on']) }}
        </span>
        <span v-if="record.updated">
          ({{ record.updated }})
        </span>
      </template>
      <template #action="{ record }">
        <div class="text-right">
          <a-tooltip placement="topRight">
            <template #title>Редактировать</template>
            <a @click="showModal(record.id)" class="mx-1"><i class="fe fe-edit"></i></a>
          </a-tooltip>
          <a-tooltip placement="top" class="mx-2">
            <template #title>Удалить</template>
            <a @click="deleteModal(record.id)"><i class="fe fe-trash"></i></a>
          </a-tooltip>
        </div>
      </template>
    </a-table>
    <div class="mt-4 text-right">
      <a-pagination
        show-size-changer
        v-if="total > query['per-page'].value"
        v-model:pageSize="query['per-page'].value"
        v-model:current="query.page.value"
        :total="total"
      />
    </div>
  </div>
</template>

<script setup>
import { mask } from "maska";
import apiClient from "@/services/axios";
import VbEditToken from "@/views/token/edit";
import {useRoute, useRouter} from "vue-router";
import VbCreateToken from "@/views/token/create";
import { onMounted, reactive, ref, toRefs, watch } from "vue";
import { CloseCircleOutlined } from "@ant-design/icons-vue";
import {message, Modal} from "ant-design-vue";

let
  timer = null,
  store = ref(''),
  stores = ref([]),
  brand = ref(''),
  brands = ref([]),
  applicationId = ref(''),
  loading = ref(false),
  confirmLoading = ref(false),
  modalVisible = ref(false),
  dataSource = ref([]),
  initialParams = {
    page: 1,
    'per-page': 10,
    sort: '',
    application_id: '',
    store_id: '',
    brand_id: ''
  },
  queryParams = reactive({...initialParams}),
  total = ref(0),
  tokenId = ref(0);

const
  columns = [
    {
      title: 'Application ID',
      sorter: true,
      dataIndex: 'application_id',
      key: 'application_id',
    },
    {
      title: 'Филиал',
      dataIndex: 'store_id',
      sorter: true,
      key: 'store_id',
      slots: { customRender: 'stores' },
    },
    {
      title: 'Добавлен',
      dataIndex: 'added_on',
      sorter: true,
      key: 'added_on',
      slots: { customRender: 'date' },
    },
    {
      title: 'Редактирован',
      dataIndex: 'updated_on',
      sorter: true,
      key: 'updated_on',
      slots: { customRender: 'updated' },
    },
    {
      key: 'action',
      slots: { customRender: 'action' },
    },
  ],
  find = (e) => {
    if (e.target.value.length > 0 && e.target.value.length < 3)
      return;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      updateParams({ application_id: e.target.value })
    }, 800);
  },
  searchByStore = (id) => {
    updateParams({ store_id: id })
  },
  searchByBrand = (id) => {
    updateParams({ brand_id: id })
  },
  updateParams = params => {
    Object.assign(queryParams, params)
  },
  tableChange = (pag, filters, sorter) => {
    if (typeof sorter.order == "string") {
      let sign = ''
      if (sorter.order === 'descend')
        sign = '-'
      updateParams({sort: sign + sorter.field})
    }
  },
  showModal = (id) => {
    tokenId.value = id
    modalVisible.value = true;
  },
  route = useRoute(),
  router = useRouter(),
  hideModal = () => {
    setTimeout(() => {
      modalVisible.value = false
    }, 100)
  },
  formattedPhone = (phone) => {
    return (phone) ? mask(phone, '+998(##) ###-##-##') : ''
  },
  clearFilters = () => {
    Object.assign(queryParams, initialParams);

    applicationId.value = ''
    store.value = ''
    brand.value = ''

    reload()
  },
  deleteModal = id => {
    Modal.confirm({
      title: 'Удаление токена',
      content: `Вы действительно хотите удалить токен?`,
      okText: 'Да',
      onOk() {
        return apiClient.delete(`token/delete?id=${id}`).then(response => {
          if (response) {
            message.success('Токен успешно удален')
          }
        }).catch(() => {
          // console.log(err)
        }).finally(() => {
          reload()
        });
      },
    });
  },
  formattedDatetime = (date) => {
    date = date.replace(/ /g,"T")
    let d = new Date(date)
    const timeFormatter = new Intl.DateTimeFormat('ru-RU', {
      timeStyle: 'medium',
    })
    const dateFormatter = new Intl.DateTimeFormat('ru-RU')
    return dateFormatter.format(d) + ' ' + timeFormatter.format(d)
  },
  getData = (params) => {
    loading.value = true
    return apiClient.get(`/token`, {
      params,
    }).then(({ data }) => {
      dataSource.value = data.data.items
      stores.value = data.data.stores
      brands.value = data.data.brands
      total.value = data.data.total
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
    });
  },
  reload = () => {
    getData({
      ...queryParams,
    })
  };

onMounted(() => {
  getData({
    ...queryParams,
  })
})
watch(queryParams, () => {
  router.push({
    name: 'tokens',
    query: {
      ...route.query,
      ...queryParams,
    },
  })
  getData({
    ...route.query,
    ...queryParams,
  })
});

const query = {...toRefs(queryParams)}

</script>

<style>
[data-vb-theme="default"] .ant-table-thead > tr > th{
  background: none;
}
.header-affix{
  border: 1px solid #e4e9f0;
  background-color: #ffffff !important;
  box-shadow: 0 4px 10px 0 #14130b0d, 0 0 10px 0 #1413110f;
  border-radius: 0.25rem;
}
</style>
